import { twMerge } from "tailwind-merge";

const HeaderTitle: React.FC<
  React.PropsWithChildren & { className?: string }
> = ({ children, className }) => (
  <div
    className={twMerge(
      "text-lg font-black text-text tablet:text-xl",
      className,
    )}
  >
    {children}
  </div>
);

export default HeaderTitle;
