import React from "react";
import { twMerge } from "tailwind-merge";

import Icon, { IconName } from "@/app/_components/icon";
import ArrowLink from "./arrowlink";

interface MenuSubItemProps {
  href: string;
  label: string;
  children?: React.ReactNode;
  icon: IconName;
}

export default function MenuSubItem({
  href,
  label,
  children = undefined,
  icon,
}: MenuSubItemProps) {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <div>
      <div className="flex items-center gap-4">
        <ArrowLink
          href={href}
          className="flex h-14 grow items-center px-4 font-extrabold text-tertiary-950 hover:text-primary-600 focus:outline-none tablet:border-b tablet:px-0 laptop:h-auto laptop:py-5"
        >
          <div className="flex items-center gap-3">
            <Icon name={icon} className="h-5 w-5" />
            {label}
          </div>
        </ArrowLink>
        {children && (
          <button
            type="button"
            className="flex h-12 w-12 items-center justify-around tablet:hidden"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <Icon
              name={isExpanded ? "chevron-up" : "chevron-down"}
              className="h-5 w-5"
            />
          </button>
        )}
      </div>
      <div className={twMerge("hidden tablet:block", isExpanded && "block")}>
        {children}
      </div>
    </div>
  );
}
