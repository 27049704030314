import Link from "next/link";
import React from "react";
import { twJoin, twMerge } from "tailwind-merge";

import Icon from "@/app/_components/icon";
import classNames from "classnames";
import { usePathname } from "next/navigation";

interface MenuItemCommonProps {
  label: string;
  isActive?: boolean;
}

interface MenuItemWithLinkProps extends MenuItemCommonProps {
  href: string;
  children?: never;
}

interface MenuItemWithChildrenProps extends MenuItemCommonProps {
  href?: never;
  children: React.ReactNode;
}

type MenuItemProps = MenuItemWithLinkProps | MenuItemWithChildrenProps;

const MenuItem: React.FC<MenuItemProps> = ({
  href,
  label,
  children = undefined,
  isActive = false,
}) => {
  const isLink = !!href;
  const currentPathname = usePathname();
  const itemIsActive =
    isActive || (isLink && currentPathname?.substring(0, href.length) === href);
  const className = classNames(
    "h-18 flex items-center px-4 font-black focus:outline-none group/item",
    "tablet:px-0",
    "laptop:p-2 laptop:font-semibold laptop:h-16",
  );

  const labelClassName = classNames(
    "laptop:decoration-4 laptop:underline-offset-[10px] laptop:group-hover:underline laptop:group-hover:decoration-primary-200 laptop:py-2",
    itemIsActive &&
      "laptop:underline laptop:decoration-primary-500 laptop:group-hover:decoration-primary-500",
  );

  return (
    <div className="group text-tertiary-950 last:border-b-0">
      {isLink ? (
        <Link href={href} className={className}>
          <span className={labelClassName}>{label}</span>
        </Link>
      ) : (
        <div className={twJoin(className, "cursor-pointer gap-2")}>
          <span className={labelClassName}>{label}</span>
          {children && (
            <>
              <Icon
                name="chevron-down"
                className="hidden h-4 w-4 group-hover:hidden laptop:block"
              />
              <Icon
                name="chevron-up"
                className="hidden h-4 w-4 group-hover:block laptop:hidden"
              />
            </>
          )}
        </div>
      )}
      {children && (
        <div
          className={twMerge(
            "mb-4",
            "tablet:mb-8 tablet:grid tablet:grid-cols-2 tablet:gap-x-10 tablet:gap-y-8 laptop:gap-x-0",
            "laptop:absolute laptop:left-1/2 laptop:top-16 laptop:z-50 laptop:hidden laptop:w-[calc(100vw_-_theme('spacing.16'))] laptop:max-w-[1360px] laptop:-translate-x-1/2 laptop:justify-evenly laptop:border-t laptop:bg-background laptop:pb-4 laptop:pt-1 laptop:text-sm laptop:shadow-lg laptop:group-hover:flex",
            "[clip-path:inset(0px_-20px_-20px_-20px)]", // cut off shadow on top side (https://www.kevinleary.net/blog/remove-box-shadows-one-side-css/)
          )}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default MenuItem;
