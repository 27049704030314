"use client";

import { useEffect, useState } from "react";
import { usePathname } from "next/navigation";
import {
  Button,
  Dialog,
  DialogTrigger,
  Modal,
  ModalOverlay,
} from "react-aria-components";
import { twMerge } from "tailwind-merge";

import Icon from "@/app/_components/icon";

export default function MobileMenu({ children }: React.PropsWithChildren) {
  const [isMenuOpen, setMenuOpen] = useState(false);

  /* Close the menu when the route changes, i.e. the user clicked a menu item */
  const pathname = usePathname();
  useEffect(() => {
    setMenuOpen(false);
  }, [pathname]);

  return (
    <DialogTrigger>
      <Button
        onPress={() => setMenuOpen(!isMenuOpen)}
        aria-label={isMenuOpen ? "Menü schließen" : "Menü öffnen"}
      >
        <Icon
          name="menu"
          className={twMerge("h-6 w-6", isMenuOpen && "hidden")}
        />
        <Icon
          name="close"
          className={twMerge("h-6 w-6", !isMenuOpen && "hidden")}
        />
        <span className="sr-only">Menü</span>
      </Button>
      <ModalOverlay
        isOpen={isMenuOpen}
        onOpenChange={setMenuOpen}
        className="overflow-none fixed inset-0 z-100 mt-14 border-t bg-black/40 backdrop-blur-sm focus:outline-none entering:animate-in entering:fade-in exiting:animate-out exiting:fade-out tablet:mt-16 tablet:flex tablet:justify-end tablet:border-0"
      >
        <Modal className="relative h-full overflow-auto bg-background entering:animate-in entering:slide-in-from-right exiting:animate-out exiting:slide-out-to-right tablet:w-[80vw]">
          <Dialog className="divide-y outline-none tablet:px-5">
            {children}
          </Dialog>
        </Modal>
      </ModalOverlay>
    </DialogTrigger>
  );
}
